/* eslint-disable no-param-reassign */
import * as React from 'react';
import { setConfig } from '@leagueplatform/core';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';

export function useDynamicConfig() {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  React.useEffect(() => {
    setConfig((config) => {
      config.careDiscovery = {
        ...config.careDiscovery,
        ...{
          masonryAppId: isMobile
            ? {
                left: 'cigna_care_homepage',
              }
            : {
                left: 'cigna_care_homepage_left',
                right: 'cigna_care_homepage_right',
              },
        },
      };

      return config;
    });
  }, [isMobile]);
}
