import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
import { cignaHealthTheme } from 'theme/cignaHealthTheme';
import { EN_STRING_OVERRIDES } from 'locales/en';
import { ASSETS_CONFIG } from 'common/asset-config';

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
  VITE_TENANT_ID,
  VITE_APP_ID,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN!,
  client_id: VITE_AUTH0_CLIENT_ID!,
  redirect_uri: window.location.origin,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

export const config: LeagueConfig = {
  core: {
    clientId: VITE_APP_ID,
    tenantId: VITE_TENANT_ID,
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    contentUrl: VITE_CONTENT_URL as string,
    appEnvironment: VITE_APP_ENV as ENVIRONMENT,
    customMethods: {
      handleLink: async (url: any) => {
        await window.open(url, '_blank', 'noreferrer');
      },
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
    ui: {
      theme: cignaHealthTheme,
      features: { pointsEnabled: true },
      components: {
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
  healthProfile: {
    ratingsBarEnabled: true,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: true,
  },
  careCollections: {
    showCareCollectionFeedbackComponent: true,
  },
  wallet: {
    showLandingPageHeaderBranding: true,
  },
  claims: {
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: false,
    },
    showTotalCostOverviewAndAdditionalResources: false,
    filterableProperties: {
      status: ['pending', 'approved', 'adjusted'],
      patientName: {
        isVisible: false,
      },
    },
    showClaimStatusDefinitions: true,
  },
};
