import { BoxProps } from '@leagueplatform/genesis-commons';
import { ABOUT_CIGNA_ROUTE, FORMS_CENTER_ROUTE } from 'common/constants';
import type { Link } from 'common/types';
import {
  doesUserHaveProductSubType,
  useGetUserProfile,
} from './use-get-user-profile.hook';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

function useAccountSettingLinks() {
  const { data } = useGetUserProfile();
  const showManagePharmacySettingsLink = doesUserHaveProductSubType(
    data,
    'MAPD',
  );

  const accountSettingLinks: Link[] = [
    {
      text: 'PERSONAL_INFO',
      to: '/sso/vendors/mycigna?redirect_key=profile',
      color: '$onSurfaceTextSubdued',
      // icon: manageProfileIcon,
      linkIcon: 'interfaceExternalLink',
      linkProps: boldTextLink,
    },
  ];

  if (showManagePharmacySettingsLink) {
    accountSettingLinks.push({
      text: 'MANAGE_PHARMACY_SETTINGS',
      to: '/sso/vendors/expressscripts?content_area=orderrx',
      color: '$onSurfaceTextSubdued',
      linkIcon: 'interfaceExternalLink',
      linkProps: boldTextLink,
    });
  }

  return accountSettingLinks;
}

// TODO: need to refactor: linksProps is used for web(as there is no color property available in UserDropDownComponent) and color props is used for mobile screen
export function useUserDropdownConfig(
  handleDeleteAccount: () => void,
): Options[] {
  const accountSettingLinks = useAccountSettingLinks();

  return [
    {
      heading: 'ACCOUNT_SETTINGS',
      items: accountSettingLinks,
    },
    {
      heading: 'SUPPORT',
      items: [
        {
          text: 'FORMS_CENTER',
          to: FORMS_CENTER_ROUTE,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          // icon: 'illustrativeDocument',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      heading: 'PRIVACY_AND_LEGAL_DOCUMENTS',
      items: [
        {
          text: 'PRIVACY_POLICY',
          to: 'https://www.cigna.com/medicare/privacy',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'PRIVACY_NOTICE',
          to: '',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'PRIVACY_FORMS',
          to: 'https://www.cigna.com/medicare/member-resources/customer-forms',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'HIPPA_NOTICE_OF_PRIVACY_PRACTICES',
          to: 'https://www.cigna.com/static/www-cigna-com/docs/cigna-health-care-and-cigna-supplemental-benefits-privacy-notice-eng.pdf',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'GRAMM_LEACH_BILLEY_ACT_NOTICE',
          to: 'https://www.cigna.com/static/www-cigna-com/docs/gramm-leach-bliley-act-privacy-notice.pdf',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'NONDISCRIMINATION_NOTICE',
          to: 'https://www.cigna.com/medicare/nondiscrimination',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'APPEALS',
          to: '',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'GRIEVANCES',
          to: '',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: 'DISCLAIMERS',
          to: '',
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
      ],
    },
    {
      heading: 'ABOUT',
      items: [
        {
          text: 'ABOUT_CIGNA_HEALTHCARE',
          to: ABOUT_CIGNA_ROUTE,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          // icon: 'statusInformationCircle2',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: 'LANGUAGE_ASSISTANCE',
          to: 'https://www.cigna.com/static/www-cigna-com/docs/multi-language-interpreter-services.pdf',
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'PRIVACY_POLICY',
          to: '',
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          // icon: 'illustrativeCheckBadge',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'TERMS_OF_SERVICE',
          to: 'https://www.cigna.com/legal/compliance/',
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          // icon: 'illustrativeDocument',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'DELETE_YOUR_ACCOUNT',
          onClick: handleDeleteAccount,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          // icon: 'interfaceDelete',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      items: [
        {
          text: 'LOG_OUT',
          to: '/sign-out',
          color: '$onSurfaceTextCritical',
          linkProps: boldTextCriticalLink,
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
  ];
}
