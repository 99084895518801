import * as LeagueWebCommon from '@leagueplatform/web-common';

type ProductSubType = 'MAPD' | 'MA';
type CustomField = { name: 'productSubType'; value: ProductSubType };

// Extend the QueryResult with `custom_fields`.
type UseGetUserProfileQueryResult = ReturnType<
  typeof LeagueWebCommon.useGetUserProfile
>;
type UseGetUserProfileWithCustomFields = UseGetUserProfileQueryResult & {
  data?: {
    customFields?: Array<CustomField>;
  };
};

/**
 * A wrapper that extends `useGetUserProfile` by adding `customFields`.
 */
export const useGetUserProfile = () => {
  const { data, isLoading, error } =
    LeagueWebCommon.useGetUserProfile() as UseGetUserProfileWithCustomFields;

  const productSubTypes: ProductSubType[] = [];
  data?.customFields?.forEach(({ name, value }) => {
    if (name === 'productSubType') {
      productSubTypes.push(value);
    }
  });

  return {
    data: {
      ...data,
      customFields: {
        productSubTypes,
      },
    },
    isLoading,
    error,
  };
};

type UseGetUserProfile = ReturnType<typeof useGetUserProfile>;

export function doesUserHaveProductSubType(
  userProfile: UseGetUserProfile['data'],
  productSubType: ProductSubType,
) {
  const showManagePharmacySettingsLink =
    userProfile?.customFields?.productSubTypes.includes(productSubType);

  return showManagePharmacySettingsLink;
}
