import { useHistory, useLocation } from '@leagueplatform/routing';
import { useAuth } from '@leagueplatform/auth-utils';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { useUserEligibility } from 'hooks/use-user-eligibility.hook';
import { FullPageError } from 'components/error/full-page-error.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, error: authError } = useAuth();
  const {
    eligible,
    details,
    loading,
    error: eligibilityError,
  } = useUserEligibility(isAuthenticated);

  // Handle initial loading states from authentication or eligibility checks
  if (isAuthenticated === undefined || loading) {
    return <FullPageLoadingSpinner />;
  }

  // Handle errors from authentication
  if (authError || !isAuthenticated) {
    history.push({
      pathname: '/',
      state: {
        /**
         * Keep track of the page the user landed on so that after auth is
         * complete we can redirect them back to the deeplink.
         */
        redirectPath: location.pathname,
      },
    });
    return null;
  }

  // Handle eligibility errors or ineligibility
  if (eligibilityError || !eligible) {
    return (
      <FullPageError
        errorType={ErrorTypes.NOT_ELIGIBLE}
        buttonLink="https://my.cigna.com/"
        buttonChildren="Go to myCigna.com"
        customMessage={details?.reasonCodeDescription}
      />
    );
  }

  return children;
};
